<template>
  <div class="overlay">
    <div class="teams">
      <span class="team">{{ homeTeam }}</span>
      <span class="points">{{ pointsHome }}</span>
      <span class="vs">VS</span>
      <span class="points">{{ pointsEnemy }}</span>
      <span class="team">{{ enemyTeam }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Stream',
    props: ['meetingId', 'time'],
    data() {
      return {
        homeTeam: '',
        enemyTeam: '',
        pointsHome: 0,
        pointsEnemy: 0,
        websocket: undefined
      }
    },
    created() {
      this.axios.get('https://stream.sportabel.de/info.php?meetingId=' + this.meetingId + '&time=' + this.time)
        .then(response => {
          console.log(response)
          this.homeTeam = response.data.teamHome
          this.enemyTeam = response.data.teamGuest

          if (this.websocket === undefined) {
            let websocket = new WebSocket('wss://hbde-live-websocket.liga.nu/nuScoreLive/socket/1')
            websocket.onopen = function () {
              websocket.send(JSON.stringify({meetingUuids: [response.data.meetingUuid]}))
            }
            websocket.onmessage = function (event) {
              const message = JSON.parse(event.data)
              console.log(message)
            }
            this.websocket = websocket
          }
      })
    },
    destroyed() {
      this.websocket.close()
    }
  }
</script>

<style>
  .overlay {
    width: 100%;
    height: 100%;

    position: relative;
  }
  .teams {
    position: absolute;
    left: 280px;
    right: 1920px;
    top: 920px;
    width: 1640px;
    padding: 2rem;
    font-size: 2rem;
    font-family: sans-serif;
    text-align: center;
  }
  .team {
    margin: 0 3rem;
  }
  .points {
    margin: 0 2rem;
  }
</style>
