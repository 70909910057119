<template>
  <div>
    Bitte übergeben Sie eine gültige Meetingid
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
